import React, { useRef } from 'react';
import SidePattern from '../img/side-pattern.png';
import Logo from '../img/logo.png';
import emailjs from '@emailjs/browser';


const Temp = () => {

    const form = useRef();

    const sendInquiries = (e) => {

        e.preventDefault();

        emailjs.sendForm('service_4zh76x6', 'template_czggila', form.current, 'lW5IvNURR-RZ1sq-U')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });


        e.target.reset();


    };

    return(
        <>
            
            <div className='banner-wrapper--banner'>
                <img src={SidePattern} className='side-pattern' alt='Prestige' />
                <div className='banner-content'>
                    <span className='title-white'>We're Re-Branding.</span>&nbsp;<span className='title-red'>Stay Tuned!</span>
                    <h1>Exciting Changes are Underway. A Fresh Look is Coming Soon!</h1>
                </div>
            </div>
            <div className='about-wrapper'>
                <img src={Logo} className='about-logo' alt='Prestige Logo' />
                <p>
                Prestige stands as a multifaceted leader in the service industry, seamlessly integrating a diverse range of services to meet the evolving needs of our clients and communities. With a commitment to excellence, innovation, and sustainable growth, we pride ourselves on delivering exceptional solutions and providing services that go beyond the ordinary across various sectors, from real estate and tourism to utilities and hospitality.
                </p>
            </div>
            <div className='contact-wrapper'>
                <span className='title'>Get In Touch</span>
                <p>
                    We'd love to hear from you! If you have any inquiries, questions, feedback,
                    or would like to learn more about our company, please don't hesitate to
                    reach out to us.
                </p>
                <form ref={form} onSubmit={sendInquiries}>
                    <div className='form-container'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <label for=''>Company Name *</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <input type='text' className='form-control' name='company' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <label for=''>Name *</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <input type='text' className='form-control' name='fullname' />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <label for=''>Mobile No. *</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <input type='text' className='form-control' name='mobile'  />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <label for=''>Email Address *</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <input type='email' className='form-control' name='email_address'  />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <label for=''>Message *</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <textarea rows={5} className='form-control' placeholder='Your message here' name='message'></textarea>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 form-group'>
                                    <button type='submit' className='btn btn-grey'>Submit</button>
                                </div>
                            </div>
                            
                            
                           
                            
                            
                            
                            
                           
                            
                            

                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default Temp;