import React from 'react';
import Logo from '../../img/logo.png';



const Header = () => {

    return(
        <div className='header-wrapper'>
            <div className='brand-name'>
                <img src={Logo} alt='Prestige' />
            </div>
        </div>
    );

}


export default Header;