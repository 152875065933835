import '../src/css/main.scss';
import Header from './components/layouts/header';
import Footer from './components/layouts/footer';
import Temp from '../src/pages/temp';

function App() {
  return (
    <div className="App">
        <div className='main-wrapper'>
            <Header />
            <div className='content-wrapper'>
              <Temp />
            </div>
            <Footer />
        </div>
    </div>
  );
}

export default App;
