import React from 'react';
import Logo from '../../img/logo.png';
import { FaMapMarker, FaPhoneVolume, FaEnvelope } from 'react-icons/fa';



const Footer = () => {

    return(
        <div className='footer-wrapper'>
            <div className='logo-container'>
                <img src={Logo} className='logo' alt='Prestige Logo' />
            </div>
            <div className='contact-details'>
                <span className='contact-title'>CONTACT US</span>
                <span className='details'><FaMapMarker /> Addax Tower | Office 5712 | Al Reem Island</span>
                <span className='details'><FaPhoneVolume /> +971 50 302 4774</span>
                <span className='details'><FaEnvelope /> info@pgw.ae</span>
            </div>
        </div>
    );
}

export default Footer;